const Section06 = () => {
  return (
    <div className="section06 av-container">
      <div className="av-row">
        <div className="container">
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/la-de-merino-forclaz?event_category=banner_merino_lp_inverno_lp_inverno&event_action=click&event_label=colecao-fleece-lp-inverno">
              <img
                className="img-desk"
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759211/lp-inverno-2021-banner-MERINO-desk.png"
                alt="Lã Mineiro | Decathlon"
              />
              <img
                className="img-mob"
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759212/lp-inverno-2021-banner-MERINO-mobile.png"
                alt="Lã Mineiro | Decathlon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section06
