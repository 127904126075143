const Section05 = () => {
  return (
    <div className="section05 av-container">
      <div className="av-row">
        <h1>MAIS OPÇÕES PARA VOCÊ</h1>
        <div className="container">
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa/?q=moletom&page=2">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/11299946/20220510-lp-inverno_moletons.png"
                alt="Moletons | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa?q=corta%20vento">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/11299945/20220510-lp-inverno_corta.png"
                alt="Corta vento| Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa?pageType=hotsite&q=inv2022-acessorios">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/11299947/20220510-lp-inverno_meias-acessorios.png"
                alt="Meias e acessorios | Decathlon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section05
