import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation } from 'swiper'

const EcoConception = () => {
  return (
    <div className="section-eco-concepcao">
      <div className="section-eco-concepcao__image">
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/banner-eco-concepcao.png"
          alt="Início do projeto"
          width="360"
          height="66"
        />
      </div>
      <div className="section-eco-concepcao__description">
        <p className="section-eco-concepcao__description--text">
          Você sabia que muitos de nossos produtos são feitos com o conceito de
          ecodesign? Aqui na Decathlon, assumimos o compromisso com o
          desenvolvimento ecológico <strong>de nossos produtos</strong>, porque
          entendemos os desafios das mudanças climáticas e do esgotamento de
          recursos naturais.{' '}
          <strong>
            Para fazer a mudança precisamos ser parte da mudança.{' '}
          </strong>{' '}
        </p>
      </div>

      <div className="section-eco-concepcao__products">
        <Swiper
          className="slick-slide-swiper-inverno"
          slidesPerView={1}
          navigation
          // pagination={{ clickable: true }}
          breakpoints={{
            1244: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 2,
            },
            425: {
              slidesPerView: 1,
            },
            320: {
              slidesPerView: 1,
            },
          }}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: 1,
          }}
          modules={[Navigation, Lazy]}
        >
          <SwiperSlide>
            <div className="section-eco-concepcao__products--item">
              <a
                href="https://www.decathlon.com.br/blusa-feminina-fleece-mh100-quechua/p"
                className="gaClick"
                data-event-action="Click Imagem"
                data-event-category="Opeco Inverno"
                data-event-label="Fleece"
              >
                <img
                  src="https://decathlonstore.vteximg.com.br/arquivos/inverno-vitrine-10.png"
                  alt="FLEECE"
                  width="170"
                  height="170"
                />
                <h3>FLEECE MH 100</h3>
                <p>Composto a 100% por fio em poliéster reciclado.</p>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="section-eco-concepcao__products--item">
              <a
                href="https://www.decathlon.com.br/jaqueta-masculina-de-trilha-impermeavel-sh100/p?aSku=Cor:preta"
                className="gaClick"
                data-event-action="Click Imagem"
                data-event-category="Opeco Inverno"
                data-event-label="Jaqueta SH100"
              >
                <img
                  src="https://decathlonstore.vteximg.com.br/arquivos/inverno-2021-vitrine.png"
                  alt="JAQUETA SH100"
                  width="170"
                  height="170"
                />
                <h3>Jaqueta SH100</h3>
                <p>Enchimento com 100% de poliéster reciclado</p>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="section-eco-concepcao__products--item">
              <a
                href="https://www.decathlon.com.br/jaqueta-masculina-de-trekking-trek-100/p?aSku=Cor:preto"
                className="gaClick"
                data-event-action="Click Imagem"
                data-event-category="Opeco Inverno"
                data-event-label="Jaqueta Trek100"
              >
                <img
                  src="https://decathlonstore.vteximg.com.br/arquivos/inverno-vitrine-8.png"
                  alt="JAQUETA TREK 100"
                  width="170"
                  height="170"
                />
                <h3>Jaqueta Trek 100</h3>
                <p>
                  Enchimento de poliéster 25% reciclado. Processo de tingimento
                  do forro Dope-Dye, que reduz em 40% a emissão de CO
                  <sub>2</sub>
                </p>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="section-eco-concepcao__products--item">
              <a
                href="https://www.decathlon.com.br/luvas-trekking-viagem-explor-100-preto/p"
                className="gaClick"
                data-event-action="Click Imagem"
                data-event-category="Opeco Inverno"
                data-event-label="Luva"
              >
                <img
                  src="https://decathlonstore.vteximg.com.br/arquivos/inverno-vitrine-7.png?v=637248003222200000"
                  alt="LUVA"
                  width="170"
                  height="170"
                />
                <h3>Luva Trek 100</h3>
                <p>Composto a 100% por fio em poliéster reciclado</p>
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <a
        href="https://www.decathlon.com.br/ecoconcepcaomontanha"
        className="section-eco-concepcao__button"
      >
        Saiba Mais
      </a>
    </div>
  )
}

export default EcoConception
