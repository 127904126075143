const Section03 = () => {
  return (
    <div className="section03 av-container">
      <div className="av-row">
        <div className="container">
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/camping-trilha-e-neve/roupas/fleece?event_category=banners_categorias_lp_inverno&event_action=click&event_label=colecao-fleece-lp-inverno#1">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/11297235/20220510-lp-inverno_fleece.png"
                alt="Fleece | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/roupas/segunda-pele?event_category=banners_categorias_lp_inverno&event_action=click&event_label=colecao-segunda-pele-lp-inverno#1">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/11297234/20220510-lp-inverno_segunda-pele.png"
                alt="Segunda Pele | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa/?q=colecao-kipsta-inverno&pageType=hotsite&event_category=espaco_marcas_lp_inverno&event_action=click&event_label=colecao-kipsta&sortType=descSold&page=1">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/11297233/20220510-lp-inverno_jaquetas.png"
                alt="Jaquetas | Decathlon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section03
