import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation, Pagination } from 'swiper'

const Section08 = () => {
  return (
    <div className="section08-blog">
      <div className="av-container">
        <div className="av-row">
          <div className="section-title">
            <h2 className="section-title__text">DICAS EM DESTAQUES</h2>
          </div>
          <p className="texto-blog">
            Se você é apaixonado por esportes igual a gente, fique de olho nas
            dicas e conselhos que nossos esportistas prepararam pra te ajudar a
            praticar seus esportes favoritos
          </p>
        </div>
      </div>
      <div className="av-container">
        <div className="av-row">
          <div className="section08-blog-post-content">
            <Swiper
              className="slick-slide-swiper-inverno"
              slidesPerView={1}
              pagination={{ clickable: true }}
              breakpoints={{
                1244: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
                1100: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 3,
                },
                425: {
                  slidesPerView: 1,
                },
                320: {
                  slidesPerView: 1,
                },
              }}
              lazy={{
                loadPrevNext: true,
                loadPrevNextAmount: 1,
              }}
              modules={[Navigation, Lazy, Pagination]}
            >
              <SwiperSlide>
                <div className="post">
                  <a href="https://souesportista.decathlon.com.br/roupas-de-frio-para-inverno-3-camadas/?event_category=espaco_blog_lp_inverno&event_action=click&event_label=roupas-para-se-aqucer">
                    <img
                      src="https://decathlonstore.vteximg.com.br/arquivos/lp-inverno-2021-blog-3-camadas.png"
                      alt="Firts post | Decatlhon"
                    />
                    <div className="texto-post">
                      <p className="titulo-post">
                        ROUPAS DE FRIO PARA SE AQUECER
                      </p>
                      <p className="conteudo-post">
                        Quando o frio chega, bate aquela tristeza de largar o
                        edredom quentinho e sair por aí trilhando, não é?
                      </p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="post">
                  <a href="https://souesportista.decathlon.com.br/tecido-especifico-para-yoga/?event_category=espaco_blog_lp_inverno&event_action=click&event_label=roupas-para-yoga">
                    <img
                      src="https://decathlonstore.vteximg.com.br/arquivos/lp-inverno-2021-blog-yoga.png"
                      alt="second post | Decathlon"
                    />
                    <div className="texto-post">
                      <p className="titulo-post">
                        TECIDO ESPECÍFICO PARA YOGA?
                      </p>
                      <p className="conteudo-post">
                        Usarmos um material e um tecido específico que nos
                        ajudará a realizar nossa prática
                      </p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="post">
                  <a href="https://souesportista.decathlon.com.br/dicas-para-manter-os-treinos-no-frio/?event_category=espaco_blog_lp_inverno&event_action=click&event_label=roupas-para-se-aqucer">
                    <img
                      src="https://decathlonstore.vteximg.com.br/arquivos/lp-atm-blog-01-picos.png"
                      alt="third post | decathlon"
                    />
                    <div className="texto-post">
                      <p className="titulo-post">
                        DICAS DE COMO MANTER OS TREINOS NO FRIO
                      </p>
                      <p className="conteudo-post">
                        Está frio e a vontade que costuma dar as caras é a de
                        ficar em casa, debaixo das cobertas?
                      </p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section08
