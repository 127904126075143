const Section02 = () => {
  return (
    <div className="section02 av-container">
      <div className="av-row">
        <div className="container">
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa/?q=colecao-quechua-inverno&pageType=hotsite&event_category=espaco_marcas_lp_inverno&event_action=click&event_label=colecao-quechua&sortType=descSold&page=1">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759221/lp-inverno-2021-marca-quechua-desk.png"
                alt="Quechua | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa/?q=colecao-forclaz-inverno&pageType=hotsite&event_category=espaco_marcas_lp_inverno&event_action=click&event_label=colecao-forclaz&sortType=descSold&page=1">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759215/lp-inverno-2021-marca-forclaz-desk.png"
                alt="Forclaz | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa/?q=colecao-wedze-inverno&pageType=hotsite&event_category=espaco_marcas_lp_inverno&event_action=click&event_label=colecao-wedze&sortType=descSold&page=1">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759223/lp-inverno-2021-marca-wedze-desk.png"
                alt="Wedze | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa/?q=colecao-kalenji-inverno&pageType=hotsite&event_category=espaco_marcas_lp_inverno&event_action=click&event_label=colecao-kalenji&sortType=descSold&page=1">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759217/lp-inverno-2021-marca-kalenji-desk.png"
                alt="Kalenji | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa/?q=colecao-domyos-inverno&pageType=hotsite&event_category=espaco_marcas_lp_inverno&event_action=click&event_label=colecao-domyos&sortType=descSold&page=1">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759213/lp-inverno-2021-marca-domyos-desk.png"
                alt="Domyos | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa/?q=colecao-kipsta-inverno&pageType=hotsite&event_category=espaco_marcas_lp_inverno&event_action=click&event_label=colecao-kipsta&sortType=descSold&page=1">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759219/lp-inverno-2021-marca-kipsta-desk.png"
                alt="Kipsta | Decathlon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section02
