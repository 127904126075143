import QuestionAnswer from 'src/components/QuestionAnswer'

const Section07 = () => {
  return (
    <section id="duvidas" className="container questions inverno">
      <QuestionAnswer
        title="Retire na loja"
        content="<span>A Decathlon oferece a possibilidade de comprar online e retirar na loja. O horário de funcionamento das lojas físicas depende de cada localidade. Confira a lista completa das lojas Decathlon aqui.
        </span>
        <br/><br/>
        <a href='https://www.decathlon.com.br/lojas&event_category=espaco_faq_lp_natal&event_action=click&event_label=faq-retire-na-loja'>SAIBA MAIS</a>"
      />

      <QuestionAnswer
        title="Frete grátis"
        content="<span>A Decathlon oferece a opção de Frete Grátis em duas modalidades:
        <br/>1) nas compras acima de R$249,99 para a cidade de São Paulo;
        <br/>2) Quando você compra online e retira em alguma loja Decathlon.
        </span>"
      />

      <QuestionAnswer
        title="Troca fácil"
        content="<span>A Decathlon realiza troca e devolução OMNI facilitada - compre no site, troque na loja. O horário de funcionamento das lojas físicas depende de cada localidade. Confira a lista completa das lojas Decathlon aqui.
        </span>
        <br/><br/>
        <a href='https://www.decathlon.com.br/lojas&event_category=faq_lp_natal&event_action=click&event_label=troca-facil'>SAIBA MAIS</a>"
      />

      <QuestionAnswer
        title="Cartão presente"
        content="Para quem ainda está em dúvida com o que presentear, uma boa saída é investir nos Cartões Presente com saldo pré-pago. Com validade de um ano, valor mínimo de recarga de R$10 e o máximo de R$1000, os Cartões Presente da Decathlon podem ser utilizados tanto nas lojas físicas quanto no site.  Você pode adquiri-los nos caixas das nossas lojas físicas."
      />
    </section>
  )
}

export default Section07
