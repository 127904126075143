import EcoConception from './EcoConception'
import Section01 from './Section01'
import Section02 from './Section02'
import Section03 from './Section03'
import Section04 from './Section04'
import Section05 from './Section05'
import Section06 from './Section06'
import Section07 from './Section07'
import Section08 from './Section08'
import './styles.scss'

const Inverno = () => {
  return (
    <>
      <main className="main" id="main-inverno">
        <Section01 />

        <Section02 />

        <Section03 />

        <Section04 />

        <Section05 />

        <EcoConception />

        <Section06 />

        <Section07 />

        <Section08 />
      </main>
    </>
  )
}

export default Inverno
