const Section01 = () => {
  return (
    <div className="section01-main">
      <div className="box-banner">
        <a href="https://www.decathlon.com.br/pesquisa?q=colecao-inverno&pageType=hotsite&event_category=banner_principa_lp_invernol&event_action=click&event_label=colecao-geral-lp-inverno">
          <img
            className="img-desk"
            src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759205/lp-inverno-2021-banner-principal-desk.png"
            alt=""
          />
          <img
            className="img-mob"
            src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759209/lp-inverno-2021-banner-principal-mobile.png"
            alt=""
          />
        </a>
      </div>
      <div className="av-row">
        <div className="box-banner">
          <a href="https://www.decathlon.com.br/pesquisa?q=colecao-inverno&pageType=hotsite&event_category=banner_principal_lp_inverno&event_action=click&event_label=colecao-geral-lp-inverno">
            <img
              className="img-desk"
              src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759210/lp-inverno-2021-LOGO-principal-DESK.png"
              alt="logo inverno | Decathlon"
            />
            <img
              className="img-mob"
              src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759208/lp-inverno-2021-LOGO-principal-mobile.png"
              alt="logo inverno | Decathlon"
            />
          </a>
        </div>
        <div className="section01-main-buttons">
          <button>
            <a href="https://www.decathlon.com.br/pesquisa/?q=colecao-feminino-inverno&pageType=hotsite&event_category=cta_genero_lp_inverno&event_action=click&event_label=colecao-feminina-lp-inverno&sortType=descDate&page=1">
              Feminino
            </a>
          </button>
          <button>
            <a href="https://www.decathlon.com.br/pesquisa/?q=colecao-masculina-inverno&pageType=hotsite&event_category=cta_genero_lp_inverno&event_action=click&event_label=colecao-masculina-lp-inverno&sortType=descDate&page=1">
              Masculino
            </a>
          </button>
          <button>
            <a href="https://www.decathlon.com.br/pesquisa/?q=colecao-infantil-inverno&pageType=hotsite&event_category=cta_genero_lp_inverno&event_action=click&event_label=colecao-infantil-lp-inverno&sortType=descDate&page=1">
              Infantil
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Section01
