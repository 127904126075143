const Section04 = () => {
  return (
    <div className="section04 av-container">
      <div className="av-row">
        <h1>COMPRE POR ESPORTE</h1>
        <div className="container">
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa?q=colecao-trilha-inverno&pageType=hotsite&event_category=espaco_esportes_lp_inverno&event_action=click&event_label=colecao-trilha">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759979/lp-inverno-2021-trilha-desk.png"
                alt="Trilha e Trekking | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa?q=colecao-ski-inverno&pageType=hotsite&event_category=espaco_esportes_lp_inverno&event_action=click&event_label=colecao-ski">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759977/lp-inverno-2021-ski-desk.png"
                alt="Ski | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa?q=colecao-corrida-inverno&pageType=hotsite&event_category=espaco_esportes_lp_inverno&event_action=click&event_label=colecao-corrida">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759970/lp-inverno-2021-corrida-desk.png"
                alt="Corrida | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa?q=colecao-pilates-e-yoga-inverno&pageType=hotsite&event_category=espaco_esportes_lp_inverno&event_action=click&event_label=colecao-pilates-e-yoga">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759975/lp-inverno-2021-pilates-desk.png"
                alt="Pilates e Yoga | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa?q=colecao-fitness-inverno&pageType=hotsite&event_category=espaco_esportes_lp_inverno&event_action=click&event_label=colecao-fitness">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759973/lp-inverno-2021-fitness-desk.png"
                alt="Fitness | Decathlon"
              />
            </a>
          </div>
          <div className="box-banner">
            <a href="https://www.decathlon.com.br/pesquisa?q=colecao-ciclismo-inverno&pageType=hotsite&event_category=espaco_esportes_lp_inverno&event_action=click&event_label=colecao-ciclismo">
              <img
                src="https://decathlonpro.vteximg.com.br/arquivos/ids/2759969/lp-inverno-2021-bike-desk.png"
                alt="Ciclismo | Decathlon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section04
